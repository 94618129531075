const openFormButton = document.querySelectorAll('.button');
const menuLink = document.querySelectorAll('.openForm');
const mainForm = document.querySelector('.form-contact');
const formClose = document.querySelector('.close-form');

if(formClose) {
    openFormButton.forEach(e=> {
        e.addEventListener('click', clk=> {
            if(clk.target.href.includes("#bezplatna_wycena")){
                mainForm.style.left = "0";
            }
        })
    })
    
    menuLink.forEach(e=> {
        e.addEventListener('click', ()=> {
            mainForm.style.left = "0";
        })
    })
    
    formClose.addEventListener('click', ()=> {
        mainForm.style.left = "-999999px";
    })

    window.addEventListener('keydown', event=> {
        if(event.keyCode === 27) {
            mainForm.style.left = "-999999px";
        }
    })
}