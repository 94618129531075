const faqQuestion = document.querySelectorAll('.faq__question');
const faqQuestionDescription = document.querySelectorAll('.faq__description');

faqQuestion.forEach( el=> {
    el.addEventListener('click', ()=>{
        faqQuestionDescription.forEach(childElement=> {
            childElement.style.position = "absolute";
        });
        el.children[1].style.position = "static";
    })
});