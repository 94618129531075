const topMenu = document.querySelector('.header');
const bars = document.querySelector('.bars');
const headerMenu = document.querySelector('.header nav ul');

window.addEventListener('scroll', ()=> {
    if(window.scrollY > 39) {
        topMenu.style.top = "0";
        topMenu.style.boxShadow = "0px 4px 14px 0px rgba(0,0,0,0.1)";
    }else {
        topMenu.style.top = "40px";
        topMenu.style.boxShadow = "none";
    }
})

bars.addEventListener('click', ()=> {
    if(headerMenu.classList.contains('menuOpened')) {
        headerMenu.style.left = `-999999px`;
        headerMenu.style.right = `inherit`;
        headerMenu.classList.remove('menuOpened');
        bars.classList.remove('isCross');
    }else {
        bars.classList.add('isCross');
        headerMenu.style.left = `inherit`;
        headerMenu.style.right = `0`;
        headerMenu.classList.add('menuOpened');
    }
});