const menuItems = document.querySelectorAll('.header nav ul li a');
const buttons = document.querySelectorAll('.button');
const bars = document.querySelector('.bars');
const headerMenu = document.querySelector('.header nav ul');
let scrollPosition = scrollY-200;

menuItems.forEach(el=> {
    el.addEventListener('click', e=> {
        if(e.target.href.includes('#')){
            setTimeout(()=> {
                scrollPosition = scrollY-120;
                window.scrollTo(0, scrollPosition);
            }, 2)
        }
        if(window.innerWidth < 1024) {
            if(headerMenu.classList.contains('menuOpened')) {
                headerMenu.style.left = `-999999px`;
                headerMenu.style.right = `inherit`;
                headerMenu.classList.remove('menuOpened');
                bars.classList.remove('isCross');
            }
        }
    })
})

buttons.forEach(el=> {
    el.addEventListener('click', e=> {
        if(e.target.href.includes('#')){
            setTimeout(()=> {
                scrollPosition = scrollY-120;
                window.scrollTo(0, scrollPosition);
            }, 2)
        }
    })
})

window.addEventListener('load', () => {
    if(window.location.hash){
        scrollPosition = scrollY-120;
        window.scrollTo(0, scrollPosition);
    }
});