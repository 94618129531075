var mapElement = document.getElementById('map');

if(mapElement){
  function init() {
                var mapOptions = {
                    zoom: 16,
                    center: new google.maps.LatLng(53.1242365, 17.8353513),

                    
                };

                var map = new google.maps.Map(mapElement, mapOptions);

                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(53.1242365, 17.8353513),
                    map: map,
                    title: 'Klimtech'
                });
            }

            init();
}