import Swiper from 'swiper';

const swiperOpinionsContainer = document.querySelector('.slider-opinions');

const mySwiper = new Swiper (swiperOpinionsContainer, {
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    pagination: {
        el: '.swiper-pagination-opinions',
        clickable: true
    },
    autoplay: {
        delay: 6000,
    }
})