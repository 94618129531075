import Swiper from 'swiper';

const swiperContainer = document.querySelector('.swiper-container');

const mySwiper = new Swiper (swiperContainer, {
    direction: 'horizontal',
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    autoplay: {
        delay: 5000,
    }
})